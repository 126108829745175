import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {
  HomeScreen, LoginScreen, DocControllerHomeScreen, DocContentsScreen, DocPresentationsScreen,
  DocVariablesScreen, DocArchievesScreen, DocContentsNewScreen, PageNotFoundScreen,
  EducatorControllerHomeScreen, EducatorCircularListScreen, EducatorNewCircularScreen,
  EducatorEvaluationListScreen, EducatorNewEvaluationScreen, EducatorLearningListScreen,
  EducatorNewLearningScreen, ContinuerControllerHomeScreen, ContinuerControllerBluePrintScreen, ContinuerControllerNewBluePrintScreen,
  CentralControllerGuideScreen, CentralControllerHomeScreen, CreateNewGuideScreen, 
} from "../screens";

const DocBlueprintRouter = (props) => {
  const { type } = props
  return (
    <Routes>
      <Route path="" index element={<DocContentsNewScreen readOnly type={type} />} />
      <Route path="edit" element={<DocContentsNewScreen edit type={type} />} />
    </Routes>
  )
}
const DocContentRouter = (props) => {
  return (
    <Routes>
      <Route path="new" element={<DocContentsNewScreen type='content' create />} />
      <Route path=":blueprintId/*" element={<DocBlueprintRouter type='content' />} />
      <Route path="/" index element={<DocContentsScreen />} />
    </Routes>
  )
}
const DocPresentationRouter = (props) => {
  return (
    <Routes>
      <Route path="new" element={<DocContentsNewScreen type='presentation' create />} />
      <Route path=":blueprintId/*" element={<DocBlueprintRouter type='presentation' />} />
      <Route path="/" index element={<DocPresentationsScreen />} />
    </Routes>
  )
}
const DocumentControllerRouter = (props) => {
  return (
    <DocControllerHomeScreen>
      <Routes>
        <Route path="C/*" element={<DocContentRouter />} />
        <Route path="P/*" element={<DocPresentationRouter />} />
        <Route path="V" element={<DocVariablesScreen />} />
        <Route path="A" element={<DocArchievesScreen />} />
        <Route path="A/:blueprintId" element={<DocBlueprintRouter />} />
        <Route path="/" index element={<Navigate replace to="C" />} />
        <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </DocControllerHomeScreen>
  )
}
const EduCircularRouter = (props) => {
  return (
    <Routes>
      <Route path="new" element={<EducatorNewCircularScreen />} />
      <Route path=":circularId/edit" element={<EducatorNewCircularScreen isEdit />} />
      <Route path="/" index element={<EducatorCircularListScreen />} />
    </Routes>
  )
}
const EduLearningRouter = (props) => {
  return (
    <Routes>
      <Route path="new" element={<EducatorNewLearningScreen />} />
      <Route path=":learnId/edit" element={<EducatorNewLearningScreen isEdit />} />
      <Route path="/" index element={<EducatorLearningListScreen />} />
    </Routes>
  )
}
const EduEvaluationRouter = (props) => {
  return (
    <Routes>
      <Route path="new" element={<EducatorNewEvaluationScreen />} />
      <Route path=":asmtId/edit" element={<EducatorNewEvaluationScreen isEdit />} />
      <Route path="/" index element={<EducatorEvaluationListScreen />} />
    </Routes>
  )
}
const EducatorControllerRouter = (props) => {
  return (
    <EducatorControllerHomeScreen>
      <Routes>
        <Route path="C/*" element={<EduCircularRouter />} />
        <Route path="L/*" element={<EduLearningRouter />} />
        <Route path="A/*" element={<EduEvaluationRouter />} />
        <Route path="/" index element={<Navigate replace to="L" />} />
        <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </EducatorControllerHomeScreen>
  )
}

const ContinuerBluePrintRouter = (props) => {
  const { type } = props
  return (
    <Routes>
      <Route path="" index element={<ContinuerControllerNewBluePrintScreen readOnly  />} />
      <Route path="edit" element={<ContinuerControllerNewBluePrintScreen edit  />} />
    </Routes>
  )
}

const ContinuerControllerRouter = (props) => {
  return (
    <ContinuerControllerHomeScreen>
      <Routes>
        <Route path="new" element={<ContinuerControllerNewBluePrintScreen  create />} />
        <Route path=":blueprintId/*" element={<ContinuerBluePrintRouter />} />
        <Route path="/" element={<ContinuerControllerBluePrintScreen />} />
        <Route path="" element={<Navigate replace to="/" />} />
        <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </ContinuerControllerHomeScreen>
  )
}
const CentralControllerRouter = (props) => {
  return (
    <CentralControllerHomeScreen>
      <Routes>
        <Route path="new" element={<CreateNewGuideScreen  create />} />
        <Route path=":guideId/*" element={<CentralGuideRouter />} />
        <Route path="/" element={<CentralControllerGuideScreen />} />
        <Route path="" element={<Navigate replace to="/" />} />
        <Route path="/*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </CentralControllerHomeScreen>
  )
}
const CentralGuideRouter = (props) => {
  return (
    <Routes>
      <Route path="" index element={<CreateNewGuideScreen readOnly  />} />
      <Route path="edit" element={<CreateNewGuideScreen edit  />} />
    </Routes>
  )
}
const MainRouter = (props) => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/D/*" index element={<DocumentControllerRouter />} />
        <Route path="/E/*" index element={<EducatorControllerRouter />} />
        <Route path="/C/*" index element={<ContinuerControllerRouter />} />
        <Route path="/G/*" index element={<CentralControllerRouter />} />
        <Route path="/" element={<Navigate replace to="/D" />} />
        <Route path="" element={<Navigate replace to="/" />} />
        <Route path="/*" element={<Navigate replace to='/page-not-found' />} />
      </Routes>
    </HomeScreen>
  )
}


function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginScreen />} exact />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
