import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, TextInput, Form, FormDialog, useNavigateBack, Menu, NMCKEditor } from "../../../../components";
import { isGuideArchived, isGuideActive, isGuideDraft, getShowCentralGuide, GuideActions, getGuideSectionReviewers, getCurrentUser, isGuideApproved, GUIDESTATUS, axios, getCentralGuides, AppActions } from "../../../../store"
import { StatusMenus, ArchiveMenu, UnarchiveMenu, DeleteMenu, Modules, Fields, SendForReview } from "./Data"
import { TopBreadCrumb, sortGuideSections, parseAndSortSectionContent, handleAddSection, getGuideBody, handleSectionDelete, handleSectionContentDelete, handleAddInputField, enableSave } from './Helpers';
import { Delete } from '@mui/icons-material';
import { VideoPlayerComponent, ImageViewer } from './VideoPlayer';
import { ActiveDialog } from './ActiveDialog';
import { ArchiveDeleteDialog } from './ArchiveDelete'
import './styles.scss'
import { EllipsisOutlined } from '@ant-design/icons';

export const CreateNewGuideScreen = (props) => {
    const { edit, readOnly, create } = props;
    const { guideId } = useParams()
    const dispatch = useDispatch()
    const ckRefs = useRef({})
    const navigate = useNavigate()
    const navigateBack = useNavigateBack()
    const user = useSelector(getCurrentUser)
    const guide = useSelector(getShowCentralGuide)
    const reviewers = useSelector(getGuideSectionReviewers)
    const draftGuides = useSelector(getCentralGuides.bind(null, 'draft'))
    const isArchive = isGuideArchived(guide)
    const isActive = isGuideActive(guide)
    const isDraft = isGuideDraft(guide)
    const [state, setState] = useState({
        modified: false,
        showDialog: '', show: !(edit || readOnly), selectedOption: false, guideNames: [], showActivateButton: false,
        contentItems: [], paraNum: 1, imageNum: 1, videoNum: 1, showModal: false, modalContent: null, guideIds: [], guideModules: []
    })
    const [guideData, setGuideData] = useState({
        title: '',
        module_name: '',
        guide_sections: [
            {
                id: '',
                order_no: '1',
                title: '',
                section_content: [
                    { id: '', type: 'paragraph', value: '', editorRef: 'para_1_0', label: 'Paragraph' },
                    { id: '', type: 'image', value: '', label: 'Image' },
                    { id: '', type: 'youtube_link', value: '', label: 'YouTube Link' }
                ]
            }
        ]
    }
    );
    useEffect(() => {
        dispatch(GuideActions.fetchCentralGuides({ status: 'draft' }))
    }, [])
    const setGuideContent = () => {
        let names = []
        let guide_ids = []
        let guide_modules = []
        if (draftGuides) {
            draftGuides.forEach((guide) => {
                names.push(guide.title)
                guide_ids.push(guide.guide_id)
                guide_modules.push(guide.module_name)
            })
        }
        setState((_) => ({ ..._, guideNames: [...names], guideIds: [...guide_ids], guideModules: [...guide_modules] }))
    }
    useEffect(() => {
        setGuideContent()
    }, [draftGuides])
    useEffect(() => {
        if (guideId) {
            dispatch(GuideActions.fetchCentralGuide({ id: guideId }));
        }
    }, [guideId])

    useEffect(() => {
        if (guideId && guide && guide.guide_details) {
            const updatedVersion = `v${parseInt(guide.version_no.substring(1)) + 1}`;
            let sections = guide.guide_details.map((sectionContent, sectionIndex) => {
                return parseAndSortSectionContent(sectionContent, sectionIndex)
            });
            sortGuideSections(sections)
            setGuideData(prevGuideData => ({
                ...prevGuideData,
                title: guide.title,
                module_name: guide.module_name,
                version_no: updatedVersion,
                guide_sections: [...sections]
            }));

            if (user.id === guide.reviewer_id) {
                setState(prevState => ({ ...prevState, showActivateButton: true }));
            }

            if (guide.status === 'deleted' || guide.message === 'Record Not Found') {
                navigate('/page-not-found');
            }
        }
    }, [guide]);


    const handleNavBack = () => {
        navigateBack()
    }
    const handleDiscardDialog = (back, e, isCreate) => {
        const showDialog = state.showDialog;
        setState(_ => ({ ..._, showDialog: '' }));
        if (back) {
            if (showDialog === 'delete' || showDialog === 'archive' || showDialog === 'unarchive' || isCreate) {
                let basePath = '/G/';
                let params = new URLSearchParams();
                // Set the tab parameter based on the dialog state or creation state
                if (isCreate) {
                    params.set('tab', 'draft');
                } else if (isArchive) {
                    params.set('tab', 'archive');
                } else if (isActive) {
                    params.set('tab', 'active');
                } else {
                    params.set('tab', 'draft');
                }
                setTimeout(() => {
                    navigate(`${basePath}?${params.toString()}`);
                }, 200);
            } else {
                handleNavBack();
            }
        }
    };
    const handleValueChange = (e) => {
        let { name, value } = e?.target || {};
        if (name === 'module_name') {
            value = value.id
        }
        const temp = { ...guideData }
        temp[name] = value;
        setGuideData(temp)
    }

    const getMenu = () => {
        if (guide && !edit && !create) {
            if (isArchive) {
                return DeleteMenu
            }
            else {
                if (isDraft || isActive) {
                    return [{ isEmpty: true }, ...ArchiveMenu, ...DeleteMenu]
                }
                else if (isActive) {
                    return ArchiveMenu
                }
            }
        }
        return null
    }
    const menu = getMenu()
    const handleUnarchiveButtonClick = () => {
        if (guide && guide.guide_id) {
            if (state.guideIds.includes(guide.guide_id)) {
                dispatch(AppActions.setAlert({
                    success: false, message:
                        'Draft version of Guide is already avilable.'
                }))
            }
            else {
                setState((_) => ({ ..._, showDialog: 'unarchive' }))
            }
        }
    }
    const handleDocsMenu = (e) => {
        setState((_) => ({ ..._, showMenu: e.target }))
    }
    const handleMenuClick = (e, menu) => {
        setState((_) => ({ ..._, showMenu: null, showDialog: menu.dialogId }))
    }
    const getOptions = (attribute) => {
        switch (attribute) {
            case 'reviewer_email': return reviewers
            case 'module_name': 
                return Modules.map(module => ({
                    ...module,
                    disabled: state.guideModules.includes(module.id)
                }));
        }
        return null
    }
    const handleEditorInstance = (editorRef, e) => {
        if (e.editor) {
            ckRefs.current[editorRef] = e.editor;
            if (Boolean(edit && guide)) {
                e.editor.setData(guide[editorRef]);
            }
        }
    };
    const handleSectionOrderChange = (e, index) => {
        let value = e.target.value
        if ((!isNaN(value) && parseInt(value) > 0) || value === '') {
            let temp = [...guideData.guide_sections]
            temp[index]['order_no'] = value === '' ? '' : value.toString();
            setGuideData(prevGuideData => ({
                ...prevGuideData,
                guide_sections: temp
            }));
        }
    }
    const handleSectionTitleChange = (e, index) => {
        let temp = [...guideData.guide_sections]
        temp[index]['title'] = e.target.value
        setGuideData((_) => ({ ..._, guide_sections: temp }))
    }
    const handleUploadImage = (e, index, section_content_index) => {
        const value = e.target.files[0];
        let temp = [...guideData.guide_sections]
        temp[index]['section_content'][section_content_index]['value'] = value
        temp[index]['section_content'][section_content_index]['url'] = ''
        setGuideData({ ...guideData, guide_sections: temp });
    }
    const handleVideoLinkChange = (e, index, section_content_index) => {
        const value = e.target.value
        let temp = [...guideData.guide_sections]
        temp[index]['section_content'][section_content_index]['value'] = value
        setGuideData({ ...guideData, guide_sections: temp });
    }
    const handleSave = async () => {
        const body = getGuideBody(guideData, ckRefs)

        if (edit && guide && guideId) {
            if (isGuideApproved(guide)) {
                body.status = GUIDESTATUS.ACTIVE;
            }
            body.append(`guide_id`, guideId);
            body.append(`guide[version_no]`, guideData['version_no'])

            dispatch(GuideActions.updateCentralGuide(body))
        }
        else {
            dispatch(GuideActions.createCentralGuide(body))
        }
        // for (let [key, value] of body.entries()) {
        //     console.log(`${key}: ${value}`);
        // }
        handleDiscardDialog(true, null, true)
    }
    const handleImageClick = (url) => {
        setState((_) => ({ ..._, modalContent: (<ImageViewer image_url={url} onClose={() => closeModal(false)} />), showModal: true }))
    };
    const handleVideoClick = (url) => {
        setState((_) => ({
            ..._, modalContent: (<VideoPlayerComponent youtube_link={url} onClose={() => closeModal(false)}
            />), showModal: true
        }))
    };
    const closeModal = () => {
        setState((_) => ({ ..._, showModal: false }))
    };
    //functions to handle actions- review, archive, unarchive and delete
    const handleSendForReview = (e) => {
        dispatch(GuideActions.sendGuideForReview({ id: guide.id, email: state.reviewer_email.id }))
        setState((_) => ({ ..._, showDialog: '', reviewer_email: null }))
    }
    const handleUnarchive = () => {
        handleDiscardDialog(true);
        const status = GUIDESTATUS.DRAFT
        const message = 'Guide  has been unarchived';
        dispatch(GuideActions.unarchiveGuide({ guide_id: guide.id, status, message }))
    }
    return (
        <>
            <div className='col w-100 h-100 screen-pad-con new-content doc'>
                <TopBreadCrumb isArchive={isArchive} isActive={isActive} isDraft={isDraft} edit={edit} create={create} />
                <div className='row mt1 '>
                    <div className='flex-grow1'>{
                        (create || edit) &&
                        <div className=" field-row-guide con-ck-container">
                            <Form
                                getOptions={getOptions}
                                fieldClass={`field-input`}
                                formData={guideData}
                                Fields={[...Fields]}
                                onInstanceReady={handleEditorInstance}
                                onChange={handleValueChange}
                            />
                        </div>
                    }

                        {(edit || create) && !isArchive && !isActive ?
                            <div className='row mt2'>
                                <TextInput name='title' label='Guide Title:' font='f7' labelClassName='w-170pix' className='w-500pix' labeltextclassname='exo2' containerParentClassName='container-guide' value={guideData.title} readOnly={edit} onChange={handleValueChange} required={true} placeholder='Enter Guide Title'
                                />
                            </div>
                            :
                            <div className='row mt1' >
                                {guide && <div className='f4 bold exo2 c238787' >{guide.guide_id}: {guide.title}</div>}
                            </div>
                        }

                    </div>
                    <div className='row header h-btn mt1' >
                        {
                            readOnly && !isArchive && !edit && !isActive &&
                            <Link to='edit' className='btn line-22 bg0133CC'>
                                <span className='f9 cFFF'>Edit</span>
                            </Link>
                        }
                        {
                            ((readOnly) && !isArchive) && guide && isDraft &&
                            <>
                                <Button label='Send for Review'
                                    onClick={() => {
                                        setState((_) => ({ ..._, showDialog: 'approver' }))
                                        dispatch(GuideActions.fetchGuideReviewerList())
                                    }}
                                />
                                <Button label='Activate' style={{ marginRight: '15px' }}
                                    disabled={!state.showActivateButton}
                                    onClick={() => {
                                        setState((_) => ({ ..._, showDialog: 'status' }))
                                    }}
                                />
                            </>
                        }
                        {
                            isArchive &&
                            <Button label='Unarchive' onClick={handleUnarchiveButtonClick} />
                        }
                        {
                            Boolean(menu) &&
                            <Button className='col v-ctr h-ctr dot-menu btn-menu' iconcomponent={<EllipsisOutlined />} variant='lite' onClick={handleDocsMenu} />
                        }
                        <Menu
                            menuItems={menu}
                            anchorEl={state.showMenu}
                            onMenuClick={handleMenuClick}
                            onClose={() => handleDocsMenu({ target: null })} />
                        <div>
                            {
                                ((!isArchive && edit) || create) &&
                                <div>
                                    <Button label='Add Section' onClick={() => { handleAddSection(guideData, setGuideData) }} />
                                    <Button label='Save' disabled={!enableSave(guideData, state.guideNames, edit, ckRefs, dispatch)} onClick={() => { handleSave() }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='shadow-box'></div>

                <div className="col" style={{ overflow: 'auto', paddingBottom: '25px', marginTop: '10px' }}>
                    {guide && !create && !edit &&
                        <div className=" f7 d-flex" style={{ marginBottom: '20px' }}> <div className='exo2 w-130pix'>Module :</div> <div className="">{guide.module_name}</div></div>
                    }
                    {
                        guideData &&
                        <div>
                            {guideData.guide_sections.map((section, index) => (
                                <div key={index} style={(edit || create) ? { maxHeight: '600px', overflow: 'auto' } : {}}>
                                    <div className='row h-btn' style={(edit || create) ? { position: 'sticky', top: '0', background: '#fff', zIndex: '1', paddingBottom: '10px' } : {}}>
                                        {((edit) || create) &&
                                            <div className='row'>
                                                <h3 className='exo2 med f7'>Section</h3>
                                                <Delete onClick={() => { handleSectionDelete(index, section.id, guideData, setGuideData) }} style={{ color: 'red', marginLeft: '7px', marginTop: '5px', fontSize: '18px' }} />
                                            </div>
                                        }
                                        {(edit || create) && !isArchive && !isActive &&
                                            <TextInput
                                                name='order_no'
                                                label='Section Order:'
                                                font='f8'
                                                labelClassName='w-130pix'
                                                className='w-190pix'
                                                labeltextclassname='exo2'
                                                containerParentClassName='container-guide'
                                                value={section['order_no']}
                                                onChange={(e) => { handleSectionOrderChange(e, index) }}
                                                required={true} />
                                        }
                                        <div>
                                            {
                                                ((!isArchive && edit) || create) &&
                                                <div>
                                                    <Button label='Add Paragraph' onClick={() => { handleAddInputField('paragraph', index, guideData, setGuideData) }} className='m10' />
                                                    <Button label='Add Image' onClick={() => { handleAddInputField('image', index, guideData, setGuideData) }} className='m10' />
                                                    <Button label='Add YouTube Link' onClick={() => { handleAddInputField('video_link', index, guideData, setGuideData) }} className='m10' />
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    {(edit || create) && !isArchive && !isActive ?
                                        <TextInput
                                            name='title'
                                            label='Section Title:'
                                            font='f8'
                                            labelClassName='w-170pix'
                                            className='w-500pix'
                                            labeltextclassname='exo2 f8'
                                            containerParentClassName='container-guide'
                                            placeholder='Enter Section Title'
                                            value={section['title']}
                                            onChange={(e) => { handleSectionTitleChange(e, index) }}
                                            required={true} />
                                        :
                                        <div className='row'>
                                            <div className="f9 row" style={{ minWidth: '900px' }}> <div className='exo2 f8 w-130pix'>Section Title :<span style={{ color: 'red', fontSize: '16px' }}> *</span></div> <div className="exo2">{section['title']}</div></div>
                                            <div className=" f8"> <span className='exo2'>Section Order :</span> <span className="field-editor exo2">{section['order_no']}</span></div>
                                        </div>
                                    }

                                    {
                                        section.section_content.map((item, section_content_index) => {
                                            return <div key={`${item.editorRef}-${index}-${section_content_index}`} className={`${(edit || create) ? '' : 'd-flex v-start'} section-el`}>
                                                <div className='f8 d-flex'>
                                                    <div className={`exo2 ${edit || create ? '' : (item.type === 'paragraph' ? 'mt1' : '')} ${!edit && !create ? 'w-130pix' : ''}`}>
                                                        {item.label} :{(item.type === 'paragraph' && (section_content_index === 0)) && <span style={{ color: 'red', fontSize: '16px' }}> *</span>}</div>
                                                    {((edit || create) && (section_content_index !== 0)) && <Delete onClick={() => { handleSectionContentDelete(index, section_content_index, item, guideData, setGuideData) }} style={{ color: 'red', fontSize: '18px', marginLeft: '7px', marginTop: '5px' }} />}
                                                </div>
                                                {
                                                    (item.type === 'paragraph') ?
                                                        <>
                                                            {(edit || create) ?
                                                                <div className='mb1'>
                                                                    {item.editorRef && <NMCKEditor
                                                                        initialData={(edit && guide && guide.guide_details) ? (item.value) : ''}
                                                                        // onChange={handleCKChange}
                                                                        onInstanceReady={handleEditorInstance.bind(null, item.editorRef)}
                                                                    />}
                                                                </div>

                                                                :
                                                                <div className='f9 txt-justify pr' style={{
                                                                    wordBreak: 'keep-all',
                                                                    whiteSpace: 'pre-wrap',
                                                                    overflowWrap: 'break-word',
                                                                    overflowX: 'auto'
                                                                }}
                                                                    dangerouslySetInnerHTML={{ __html: item.value }} />
                                                            }
                                                        </>
                                                        :
                                                        (item.type === 'image') ?
                                                            <div className=' h-100 ' style={{ paddingTop: '3px' }}>
                                                                {(edit || create) ?
                                                                    <TextInput
                                                                        type='file'
                                                                        selectLabel='Select Image'
                                                                        className='w-40'
                                                                        onChange={content => handleUploadImage(content, index, section_content_index)}
                                                                        accept='.png,.jpg,.jpeg'
                                                                        placeholder='Upload Image'
                                                                        value={(item.url) ? { name: item.value } : item.value}
                                                                    />
                                                                    :
                                                                    <div className='f9' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { handleImageClick(item.url) }}>
                                                                        {item.value}
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            (item.type === 'youtube_link') ?
                                                                <div className=' h-100 ' style={{ paddingTop: '3px' }}>
                                                                    {(edit || create) ?
                                                                        <TextInput
                                                                            type='link'
                                                                            placeholder='Add YouTube Link'
                                                                            className='w-40'
                                                                            onChange={content => handleVideoLinkChange(content, index, section_content_index)}
                                                                            value={item.value}
                                                                        />
                                                                        :
                                                                        <div className='f9' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { handleVideoClick(item.value) }}>
                                                                            {item.value}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                : null
                                                }


                                            </div>
                                        })
                                    }
                                    <hr className='hr-section' style={(edit || create) ? { position: 'sticky', bottom: '0', zIndex: '1' } : {}} />
                                </div>
                            ))}
                        </div>
                    }
                    {state.showModal && (
                        <>{state.modalContent}</>
                    )}
                </div>
            </div>
            {
                state.showDialog === 'approver' &&
                <FormDialog
                    title='Send for Review'
                    titleClass='c238787 med f6 exo2'
                    className='approval-dialog'
                    onClose={handleDiscardDialog.bind(null, false)}
                    leftBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
                    rightBtn={{
                        label: "Send",
                        onClick: handleSendForReview,
                        disabled: !state.selectedOption
                    }} >
                    <div className='col content'>
                        <Form
                            fieldClass='approval-input'
                            formData={{ reviewer_email: state.reviewer_email }}
                            Fields={SendForReview}
                            getOptions={getOptions}
                            onChange={(e) => {
                                setState((_) => ({ ..._, reviewer_email: e.target.value, selectedOption: true }))
                            }}
                        />
                    </div>
                </FormDialog>
            }
            {
                Boolean(state.showDialog === 'delete' || state.showDialog === 'archive') &&
                <ArchiveDeleteDialog
                    user={user}
                    guide={guide}
                    isDelete={state.showDialog === 'delete'}
                    onClose={handleDiscardDialog} />
            }
            {
                state.showDialog === 'unarchive' &&
                <FormDialog
                    className={`status-popup`}
                    title={"Unarchive Guide?"}
                    onClose={handleDiscardDialog.bind(null, false)}
                    leftBtn={{ label: 'Unarchive', className: 'bgActive', onClick: handleUnarchive }}
                    rightBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
                >
                    <p className='f7 c00085'>
                        This action will unarchive this Guide.<br />
                        Its status will be updated to "Draft".<br />
                    </p>
                </FormDialog>
            }
            {
                state.showDialog === 'status' &&
                <ActiveDialog
                    user={user}
                    guide={guide}
                    onClose={handleDiscardDialog}
                />
            }
        </>
    )
}
